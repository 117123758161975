import React, { useState } from "react";
import { Button, Card, Dimmer, Image, Loader } from "semantic-ui-react";
import ModalExampleModal from "./modal";

const CardExampleCard = ({ url, id, caption, addVotes }) => {
  const [responseMessage, setResponseMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleVote = () => {
    const votesCast = Number(window?.localStorage?.getItem("votes")) || 0;
    if (votesCast < 3) {
      setLoading(true);
      const url =
        "https://sheets.googleapis.com/v4/spreadsheets/16Tn_A1Belty-mL5_dZJZusHWk3uVvaqT7Rplh3hNBgc/values/images";

      fetch(`${url}?key=${process.env.GATSBY_API_KEY}`)
        .then((response) => response.json())
        .then((data, i) => {
          const imageArray = data?.values?.map((value) => {
            if (i !== 0) {
              return {
                imageId: value[0],
                votes: value[3],
              };
            }
          });

          const imageRecord = imageArray?.find((image) => image.imageId == id);
          const votes = Number(imageRecord?.votes) + 1;
          const rowNumber = Number(id + 1);
          const token = "token";
          fetch(`${process.env.GATSBY_SHEETS_ENDPOINT}`, {
            method: "POST",
            body: JSON.stringify({
              data: [
                { id: `${id}`, votes: "1", date: new Date().toDateString() },
              ],
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((response) => response.json())
            .then((json) => {
              setResponseMessage("Thanks - your vote has been recorded");
              setLoading(false);
              window.localStorage.setItem("votes", `${votesCast + 1}`);
              addVotes();
            });
        });
    } else {
      setResponseMessage("Sorry - you have already used 3 votes");
    }
  };

  return (
    <>
      <Card
        style={{
          minHeight: "450px",
        }}
      >
        <Image src={url} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{caption}</Card.Header>
        </Card.Content>
        <div
          style={{
            margin: "auto",
            paddingBottom: "16px",
          }}
        >
          {loading && (
            <Dimmer active>
              <Loader>Loading</Loader>
            </Dimmer>
          )}
          <div
            style={{
              marginLeft: "16px",
            }}
          >
            <ModalExampleModal
              imageUrl={url}
              onClick={handleVote}
              caption={caption}
            />
            <Button
              primary
              onClick={handleVote}
              style={{
                marginTop: "16px",
                borderRadius: "0",
                textTransform: "uppercase",
              }}
            >
              Vote for this image
            </Button>
          </div>
        </div>
        {!!responseMessage && (
          <div style={{ margin: "16px" }}>{responseMessage}</div>
        )}
      </Card>
    </>
  );
};

export default CardExampleCard;
