import React from "react";
import { Button, Header, Image, Modal } from "semantic-ui-react";

function ModalExampleModal({ imageUrl, onClick, caption }) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    onClick();
    setOpen(false);
  };
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>ENLARGE IMAGE</Button>}
    >
      <Modal.Header>{caption}</Modal.Header>
      <Modal.Content image>
        <Image size="massive" src={imageUrl} wrapped />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          CLOSE
        </Button>
        <Button
          content="VOTE FOR THIS IMAGE"
          labelPosition="right"
          icon="checkmark"
          onClick={handleClick}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalExampleModal;
