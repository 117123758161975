import React, { useEffect, useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import { Container } from "semantic-ui-react";
import { Images } from "../components/images";

const IndexPage = () => {
  const [votesCast, setVotesCast] = useState();
  useEffect(() => {
    setVotesCast(window.localStorage.getItem("votes") || "0");
    console.log("api:", process.env.API_KEY);
  }, []);

  const handleAddVote = () => {
    setVotesCast(Number(votesCast) + 1);
  };

  return (
    <Layout>
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            googleHomeSheet {
              heroImage
              description
              title
            }
          }
        `}
        render={({ googleHomeSheet: { title, description, heroImage } }) => (
          <>
            <header
              style={{
                margin: "16px",
              }}
            ></header>
            <Container>
              {votesCast < 3 ? (
                <h3>
                  Please vote for your 3 favourite entries - you have{" "}
                  {3 - votesCast} votes left
                </h3>
              ) : (
                <h3>You have used your 3 votes. Thanks for taking part!</h3>
              )}
              <div>
                <Images addVotes={handleAddVote} />
              </div>
            </Container>
          </>
        )}
      />
    </Layout>
  );
};

export default IndexPage;
