import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Grid } from "semantic-ui-react";
import { Container } from "ui-start";
import CardExampleCard from "./imageCard";

export const Images = ({ addVotes }) => {
  const [images, setImages] = useState([]);
  const data = useStaticQuery(graphql`
    query {
      allGoogleImagesSheet {
        edges {
          node {
            imageId
            imageUrl
            caption
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (data) {
      const imageArray = data.allGoogleImagesSheet.edges.map((object) => {
        return {
          caption: object.node.caption,
          imageId: object.node.imageId,
          imageUrl: object.node.imageUrl,
        };
      });
      setImages(imageArray);
    }
  }, [data]);
  return (
    <Grid>
      {images.map(({ caption, imageId, imageUrl }) => (
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Container centerHorizontal>
            <CardExampleCard
              url={imageUrl}
              caption={caption}
              id={imageId}
              addVotes={addVotes}
            />
          </Container>
        </Grid.Column>
      ))}
    </Grid>
  );
};
